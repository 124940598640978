import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/jaws-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1975 killer shark movie, Jaws"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 11</h1>
                    <h2>Jaws (Bonus)</h2>
                    <h3>July 4, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Steven Spielberg // <b>Starring:</b> Roy Scheider &amp; Richard Dreyfus</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bryan-jude-white/embed/episodes/11-Jaws-Bonus-e26ho1f" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe Horror Podcast episode 11, Jaws"></iframe>
                        </div>
                        <p><Link to="/transcripts/jaws">Read the episode transcript</Link></p>
                        <p>It's a holiday bonus episode for July 4th! Celebrating Steven Spielberg's landmark accomplishment and one of the all-time greatest movies, Jaws! It's a stealth examination of 70's masculinity as the old man plays tug of war against the new man while a monstrous great white shark terrorizes an island vacation destination.</p>
                        <p><OutboundLink href="https://www.youtube.com/watch?v=U1fu_sA7XhE" target="_blank" rel="noreferrer">Trailer</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/madman">Next Episode</Link></li>
                        <li><Link to="/episodes/the-old-dark-house">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)